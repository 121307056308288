#about{
    background-color: darken($bg-color,10%);
    border-bottom:5px solid $primary-color;

    .cell{
        overflow: hidden;
    }

    img{
        width:110%;
        max-width: 108%;
    }

    .content{
        padding: 20%;

         @include breakpoint(small only) { 
           padding: 20% 10%;
        } 
    }
}