#message{
    background-image: url(../img/Message.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    position: relative;

    h2{
        position: relative;
        padding: 10%;
        color: $bg-color;
        font-size: 4rem;
        text-align: center;
        z-index: 2;

        @include breakpoint(small only) { 
            font-size: 3rem;
        }  
    }
    
    .overlay{
        position: absolute;
        background-color: rgba(0,0,0,0.6);
        height:100%;
        width: 100%;
        top:0;
        left:0;
        z-index: 1;
    }
}