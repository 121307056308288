.in-scroll{
    background-color: rgba(0,0,0,0.8);
}

.top-bar{
    background-color: transparent;

    ul{
        background-color: transparent;
    }

    .menu-text{
        color:$bg-color;

         @include breakpoint(small only) { 
          display:none;
        }
    }

    @include breakpoint(small only) { 
         background-color: $color;
    }
}

.menu {
    
    li{
        a{
            color:$bg-color;

            &:hover{
                background-color: $primary-color;
            }
        }
        
        
         @include breakpoint(small only) { 
           width:50%;
        }
        
    }
   

}