*{
    font-family: 'Hammersmith One', sans-serif;
}

h1,h2,h3{
      font-family: 'Hammersmith One', sans-serif;
}

.button{
    display:block;
    background-color: $primary-color;
    margin: 20px auto;
}