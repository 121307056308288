#splash{
    background-image: url(../img/Splash.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    margin-top:-55px;
    border-bottom:5px solid $primary-color;
    
    .grid-x{
        height:100vh;
    }
    
    h1,h2{
        text-align: center;
        color: $bg-color;
        font-size: 6rem;
        text-transform: uppercase;
        text-shadow: 2px 2px 10px $color;

        @include breakpoint(small only) { 
            font-size: 2rem;
        }
    }

    h1{
        font-size:2rem;
    }

    @include breakpoint(small only) { 
         margin-top:0;
    }
}