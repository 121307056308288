#plans {
  padding-top: 5%;
  padding-bottom: 5%;

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }

  .plan {
    border: 1px solid lighten($color, 60%);

    &.dedicated-desk {
      h3 {
        background-image: url(../img/Dedicated-Desk.jpg);
        background-size: cover;
      }
    }

    &.dedicated-office {
      h3 {
        background-image: url(../img/Dedicated-Office.jpg);
        background-size: cover;
      }
    }

    h3 {
      text-align: center;
      text-transform: uppercase;
      color: $bg-color;
      padding: 5% 0;
      text-shadow: 1px 1px 8px $color;
    }

    h4 {
      text-align: center;
    }

    .amount {
      text-align: center;
      font-size: 3rem;
      margin: 30px 0;

      small {
        color: $primary-color;
        margin-left: -10px;
        font-size: 60%;
      }
    }

    ul {
      width: 65%;
      margin: 30px auto;

      li {
        color: lighten($color, 30%);
      }
    }

    .button {
      width: 80%;
    }

    @include breakpoint(small only) {
      margin-bottom: 30px;
    }
  }
}
