.reveal{
    h2{
        color: $primary-color;
        border-bottom: 2px solid $primary-color;
        margin-bottom: 30px;
    }

    label{
       margin-bottom: 30px;

        &[for^=checkbox]{
            @include breakpoint(small only) { 
                width:80%;
            } 
        }

        @include breakpoint(small only) { 
           margin-bottom: 15px;
        }   
    }

    button{
        width: 80%;
    }
}