#services{
    padding-top: 5%;
    padding-bottom: 5%;

    h2,p,i{
        text-align: center;
    }

    h2{
        margin-bottom:50px;

        @include breakpoint(small only) { 
            margin-bottom:30px;
        }  
    }

    i{
        display: block;
        margin: 20px auto;
        font-size: 4rem;
        color: $primary-color;
    }

    p{
        margin-bottom: 50px;
    }

    @include breakpoint(small only) { 
        padding-top: 20%;
        padding-bottom: 20%;
    }  
}